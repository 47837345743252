<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Đối soát Sacombank</h6></b-col>
                  <b-col class="text-right">
                    <a href="/#/reconcile/sacombank" class="btn btn-success">
                      <i class="fa fa-list"></i>
                      Danh sách
                    </a>
                  </b-col>
                </b-row>
              </template>
              <div>
                <b-form @submit="onSubmit">
                  <b-form-group id="input-group-4">
                    <b-form-file
                      v-model="file"
                      :state="Boolean(file)"
                      placeholder="Choose a file or drop it here...(xls, xlsx)"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <b-button type="submit" variant="primary">Submit</b-button>
                </b-form>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import CmsRepository from "@/core/repositories/cmsRepository";

export default {
  name: "Create",
  mixins: [Common],
  components: {},
  data() {
    return {
      file: null,
      extensions: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 'application/vnd.ms-excel'],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Đối soát Sacombank" },]);
  },
  methods: {
    onSubmit() {
      if (!this.file) {
        alert("Vui lòng chọn file");
        return false;
      }

      if (this.extensions.indexOf(this.file.type) === -1) {
        alert("File không đúng định dạng, vui lòng kiểm tra lại");
        return false;
      }

      if (this.file.size <= 0) {
        alert("File rỗng, vui lòng kiểm tra lại");
        return false;
      }

      let param = new FormData();
      param.append("file", this.file);

      CmsRepository.addFileSacombank(param).then(function(response) {
          if (response.data.error_code) {
            alert(response.data.message);
            return;
          }
          alert("Đẩy file thành công");
          return;
        })
        .catch(function () {
          alert("Có lỗi xảy ra");
      });

      //console.log(this.file);

      // if (upload.error_code !== 0) {
      //   alert("Có lỗi xảy ra, vui lòng thử lại");
      //   return false;
      // }


    },
  },
};
</script>
